import React from 'react';
import backgroundImage from '../assets/0.png';

import { faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { motion } from 'framer-motion';

const RecommendedBadge = () => (
    <motion.div
        initial={{ scale: 0.9, opacity: 0.5 }}
        animate={{ opacity: [0.5, 2, 0.8], transition: { duration: 2.5, repeat: Infinity } }}
        className="absolute top-0 right-0 bg-green-800 px-2 py-1 mr-[-7px] mt-[-2px] rounded-tr-lg rounded-bl-lg flex items-center"
    >
        <FontAwesomeIcon icon={faStar} className="text-green-100 mr-1" />
        <span className="text-sm font-semibold text-green-100">Recomendado</span>
    </motion.div>
);

const BestSellerBadge = () => (
    <motion.div
        initial={{ scale: 0.9, opacity: 0.5 }}
        animate={{ opacity: [0.5, 2, 0.8], transition: { duration: 2.5, repeat: Infinity } }}
        className="absolute top-0 right-0 bg-yellow-400 text-white px-2 py-1 mr-[-7px] mt-[-2px] rounded-tr-lg rounded-bl-lg flex items-center"
    >
        <FontAwesomeIcon icon={faStar} className="text-yellow-100 mr-1" />
        <span className="text-sm font-semibold text-yellow-100">Mais Escolhido</span>
    </motion.div>
);

const sendMessageWhatsApp = (planTitle) => {
    const message = `Oi, gostaria de mais informações sobre o plano "${planTitle}". Pode me ajudar?`;
    window.open(`https://api.whatsapp.com/send/?phone=5511988046477&text=${encodeURIComponent(message)}&type=phone_number&app_absent=0`, '_blank', 'toolbar=no, location=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=600, height=600');
};

const PlanCard = ({ title, features, recommended, bestSeller }) => {
    return (
      <div className={`bg-white rounded-lg overflow-hidden shadow-lg p-6 relative ${bestSeller ? 'border-yellow-400 border-4' : ''}`}>
        {recommended && <RecommendedBadge />}
        {bestSeller && <BestSellerBadge />}
        <h2 className="text-lg font-semibold mb-4 text-purple-900 border-b-2 border-purple-300 pb-2">{title}</h2>
        <ul className="text-sm text-gray-600 mb-16">
          {features.map((section, index) => (
            <li key={index}>
              <h3 className="font-semibold text-purple-900 mb-2">{section.title}</h3>
              <ul>
                {section.items.map((item, itemIndex) => (
                  <li key={itemIndex} className="flex items-center py-1">
                    <FontAwesomeIcon icon={faArrowAltCircleRight} className="w-4 h-4 mr-2 text-purple-700" />
                    <span>{item}</span>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
        <button className="bg-purple-700 hover:bg-purple-900 text-white font-bold py-2 px-4 rounded absolute bottom-4 left-1/2 transform -translate-x-1/2 w-4/5"
          onClick={() => sendMessageWhatsApp(title)}>
          Conferir
        </button>
      </div>
    );
  };

const PlanCards = () => {
    const plans = [
        {
            title: 'Plano Primário',
            features: [
                {
                    title: 'Principais Recursos',
                    items: [
                        'Site Básico',
                        'Performance Padrão',
                        'Manutenções Simples Com 50% de Desconto',
                        'Gerenciamento e Devops Grátis',
                        'Domínio Grátis Por um Ano',
                        'Certificado Grátis',
                        'Email Grátis com Domínio Próprio',
                    ],
                },
                {
                    title: 'Segurança',
                    items: [
                        'App de Firewall para Web',
                        'Nameserver Protegidos com a Cloudflare',
                        'Detector de Malware',
                    ],
                }
            ],
        },
        {
            title: 'Plano Premium',
            features: [
                {
                    title: 'Principais Recursos',
                    items: [
                        'Site Intermediário',
                        '5x mais Performance',
                        'Manutenções Simples Grátis',
                        'Manutenções Avançadas com 50% de Desconto',
                        'Gerenciamento e Devops Grátis',
                        'Domínio Grátis Por um Ano',
                        'Certificado Grátis',
                        'Backup Diario',
                        'Email Grátis com Domínio Próprio',
                    ],
                },
                {
                    title: 'Segurança',
                    items: [
                        'App de Firewall para Web',
                        'Nameserver Protegidos com a Cloudflare',
                        'Detector de Malware',
                    ],
                }
            ],
            recommended: true,
        },
        {
            title: 'Plano Master',
            features: [
                {
                    title: 'Principais Recursos',
                    items: [
                        'Diversos tipos de Aplicações',
                        'Todas as Manutenções Inclusas ',
                        'Gerenciamento e Devops Grátis',
                        '2 Domínios Grátis Por um Ano',
                        'Emails Ilimitados com Domínio Próprio',
                        'Certificado Grátis',
                        'Backup Diario',
                        'Personalização Total Plano'
                    ],
                },
            ],
            bestSeller: true
        }
    ];

    return (
        <div
            id="plan"
            style={{
                backgroundImage: `url(${backgroundImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
            }}
            className="min-h-screen flex flex-col justify-center items-center text-white font-montserrat relative"
        >
            <div className="container px-4 mx-auto py-12 text-center">
                <h1 className="text-4xl font-bold mb-8 text-white">Melhores Planos</h1>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
                    {plans.map((plan, index) => (
                        <PlanCard key={index} title={plan.title} features={plan.features} recommended={plan.recommended} bestSeller={plan.bestSeller} />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default PlanCards;
