import React, { useEffect, useState } from 'react';
import Lottie from 'react-lottie';
import { motion, useAnimation } from 'framer-motion';
import animationData1 from '../assets/animations/code.json';
import animationData2 from '../assets/animations/carregamento.json';
import animationData3 from '../assets/animations/screen-code.json';

const LoadingScreen = ({ setLoading }) => {
  const [animationIndex, setAnimationIndex] = useState(0);
  const animations = [animationData1, animationData2, animationData3];
  const texts = ['Bem-vindos', 'Prepare-se para a melhor experiência', ''];
  const controls = useAnimation();

  useEffect(() => {
    const interval = setInterval(() => {
      setAnimationIndex(prevIndex => (prevIndex + 1) % animations.length);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000 * animations.length);

    return () => clearTimeout(timer);
  }, [setLoading]);

  useEffect(() => {
    controls.start({ opacity: 1, y: 0 });
    const handleScroll = () => {
      if (window.scrollY > 100) {
        controls.start({ opacity: 0, y: 50 });
      } else {
        controls.start({ opacity: 1, y: 0 });
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [controls]);

  return (
    <motion.div
      className="bg-black min-h-screen flex items-center justify-center"
      initial={{ opacity: 0, y: 50 }}
      animate={controls}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className="w-full max-w-lg">
        <div className="relative">
          <p className="absolute top-0 left-0 right-0 text-center text-white text-2xl font-bold">
            {texts[animationIndex]}
          </p>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5, duration: animationIndex === 2 ? 1.5 : 1 }}
          >
            <Lottie
              isClickToPauseDisabled={true}
              options={{
                loop: true,
                autoplay: true,
                animationData: animations[animationIndex],
              }}
            />
          </motion.div>
        </div>
      </div>
    </motion.div>
  );
};

export default LoadingScreen;
