import React from 'react';
import { motion } from 'framer-motion';
import backgroundImage from '../assets/2.png';
import { Helmet } from 'react-helmet';

const Service = ({ nome, descricao }) => {
    return (
        <motion.div
            className="bg-purple-800 shadow-md p-4 md:p-6 rounded-lg"
            whileHover={{ scale: 1.05 }}
        >
            <h3 className="text-lg md:text-xl font-semibold mb-2 text-white">{nome}</h3>
            <p className="text-sm md:text-base text-white">{descricao}</p>
        </motion.div>
    );
};

const OurServices = () => {
    const servicos = [
        {
            id: 1,
            nome: 'Desenvolvimento Web',
            descricao: 'Crie uma presença online impactante com nossos websites responsivos e otimizados. Oferecemos design moderno, usabilidade intuitiva e performance excepcional para garantir a melhor experiência para os usuários.',
        },
        {
            id: 2,
            nome: 'Aplicativos Móveis',
            descricao: 'Transforme suas ideias em realidade com nossos aplicativos móveis personalizados. Desenvolvemos soluções inovadoras para iOS e Android, atendendo às necessidades específicas de cada cliente. Garanta presença digital e engajamento com seu público-alvo.',
        },
        {
            id: 3,
            nome: 'Desenvolvimento de SaaS',
            descricao: 'Alcance seus objetivos de negócios com nossas soluções de Software as a Service (SaaS). Desenvolvemos aplicações customizadas para suprir suas necessidades, oferecendo escalabilidade, segurança e facilidade de uso.',
        },
        {
            id: 4,
            nome: 'Consultoria de Performance',
            descricao: 'Maximize o desempenho do seu negócio online com nossa consultoria especializada em performance. Identificamos oportunidades de otimização, implementamos melhores práticas e garantimos que seu website alcance todo seu potencial.',
        },
        {
            id: 5,
            nome: 'Configurações de Servidores',
            descricao: 'Assegure a estabilidade e segurança da sua infraestrutura com nossas configurações de servidores personalizadas. Oferecemos soluções robustas e escaláveis para garantir o funcionamento contínuo e eficiente dos seus sistemas.',
        },
        {
            id: 6,
            nome: 'Saiba mais...',
            descricao: 'Entre em contato para ver outros diversos serviços que oferecemos.',
        },
    ];

    return (
        <div
            id="services"
            style={{
                backgroundImage: `url(${backgroundImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
            }}
            className="min-h-screen flex flex-col justify-center items-center text-white font-montserrat relative"
        >
            <Helmet>
                <meta name="description" content="Descubra como nossos serviços de desenvolvimento de software e tecnologia podem impulsionar o seu negócio. Oferecemos soluções personalizadas e inovadoras para atender às suas necessidades digitais. Nossa equipe experiente está pronta para desenvolver aplicativos web, móveis e de desktop, implementar sistemas de gerenciamento de banco de dados, fornecer consultoria em TI e muito mais. Entre em contato conosco para transformar suas ideias em realidade digital." />
                <meta name="keywords" content="desenvolvimento de software, desenvolvimento web, aplicativos móveis, tecnologia da informação, consultoria em TI, inteligência artificial, machine learning, blockchain, Internet das Coisas, segurança cibernética, nuvem, big data, análise de dados, programação, codificação, inovação tecnológica" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>
            <div className="container mx-auto py-8 md:py-12 text-center">
                <h2 className="text-2xl md:text-3xl font-bold mb-4 md:mb-8">Nossos Serviços</h2>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-8">
                    {servicos.map(servico => (
                        <Service key={servico.id} nome={servico.nome} descricao={servico.descricao} />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default OurServices;
