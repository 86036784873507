import React from 'react';
import Lottie from 'react-lottie';
import animationData from '../assets/animations/cll-msg.json';
import { FaLinkedin, FaWhatsapp, FaEnvelope } from 'react-icons/fa';
import backgroundImage from '../assets/bg-c.png';
import { Helmet } from 'react-helmet';

const Contact = () => {
    const sendMessageLinkedIn = () => {
        window.open('https://www.linkedin.com/company/horizoncode', '_blank');
    };

    const sendMessageWhatsApp = () => {
        window.open('https://api.whatsapp.com/send/?phone=5511988046477&text=Oi%2C+gostaria+de+um+software+pode+me+ajudar%3F&type=phone_number&app_absent=0', '_blank', 'toolbar=no, location=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=600, height=600');
    };

    const sendMessageEmail = () => {
        window.open('mailto:erisson.developer@gmail.com');
    };

    return (
        <div
            id='contact'
            style={{
                backgroundImage: `url(${backgroundImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
            }}
            className="min-h-screen flex flex-col justify-center items-center text-white font-montserrat relative"
        >
            <Helmet>
                <meta name="description" content="Descubra como nossos serviços de desenvolvimento de software e tecnologia podem impulsionar o seu negócio. Oferecemos soluções personalizadas e inovadoras para atender às suas necessidades digitais. Nossa equipe experiente está pronta para desenvolver aplicativos web, móveis e de desktop, implementar sistemas de gerenciamento de banco de dados, fornecer consultoria em TI e muito mais. Entre em contato conosco para transformar suas ideias em realidade digital." />
                <meta name="keywords" content="desenvolvimento de software, desenvolvimento web, aplicativos móveis, tecnologia da informação, consultoria em TI, inteligência artificial, machine learning, blockchain, Internet das Coisas, segurança cibernética, nuvem, big data, análise de dados, programação, codificação, inovação tecnológica" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>
            <div className="flex items-center justify-center mb-4">
                <Lottie
                    options={{
                        animationData: animationData,
                    }}
                    isClickToPauseDisabled={true}
                    width={100}
                    height={100}
                />
            </div>
            <h1 className="text-5xl font-bold mb-4 text-center">Contate-nos</h1>
            <div className="flex justify-center space-x-4">
                <button
                    className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-full shadow-md flex items-center justify-center"
                    onClick={sendMessageLinkedIn}
                >
                    <FaLinkedin className="mr-2" />
                    LinkedIn
                </button>
                <button
                    className="bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded-full shadow-md flex items-center justify-center"
                    onClick={sendMessageWhatsApp}
                >
                    <FaWhatsapp className="mr-2" />
                    WhatsApp
                </button>
                <button
                    className="bg-red-500 hover:bg-red-600 text-white py-2 px-4 rounded-full shadow-md flex items-center justify-center"
                    onClick={sendMessageEmail}
                >
                    <FaEnvelope className="mr-2" />
                    Email
                </button>
            </div>
            <footer className="absolute bottom-0 left-0 right-0 text-center p-4 bg-gray-800 text-gray-300">
                <p>&copy; {new Date().getFullYear()} HorizonCode | Direitos reservados</p>
                <p>CNPJ: 54.566.701/0001-11</p>
            </footer>
        </div>
    );
};

export default Contact;
