import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { animateScroll } from 'react-scroll';
import { FaArrowUp, FaWhatsapp } from 'react-icons/fa';
import { Helmet } from 'react-helmet';

import Home from './components/Home';
import OurServices from './components/OurServices';
import LoadingScreen from './components/Loading';
import Employee from './components/Employees';
import Contact from './components/Contact';
import PlanCards from './components/PlantCards';

const App = () => {
  const [loading, setLoading] = useState(true);

  const scrollToTop = () => {
    animateScroll.scrollToTop({
      duration: 3000,
      smooth: 'easeOutQuint'
    });
  };

  const openWhatsApp = () => {
    window.open('https://api.whatsapp.com/send/?phone=5511988046477&text=Oi%2C+gostaria+de+um+software+pode+me+ajudar%3F&type=phone_number&app_absent=0', '_blank', 'toolbar=no, location=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=600, height=600');
  };

  return (
    <div>
      <Helmet>
        <title>{`Horizon Code - Desenvolvimento de Software e Tecnologia`}</title>
        <meta name="description" content="Descubra como nossos serviços de desenvolvimento de software e tecnologia podem impulsionar o seu negócio. Oferecemos soluções personalizadas e inovadoras para atender às suas necessidades digitais. Nossa equipe experiente está pronta para desenvolver aplicativos web, móveis e de desktop, implementar sistemas de gerenciamento de banco de dados, fornecer consultoria em TI e muito mais. Entre em contato conosco para transformar suas ideias em realidade digital." />
        <meta name="keywords" content="desenvolvimento de software, desenvolvimento web, aplicativos móveis, tecnologia da informação, consultoria em TI, inteligência artificial, machine learning, blockchain, Internet das Coisas, segurança cibernética, nuvem, big data, análise de dados, programação, codificação, inovação tecnológica" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      {loading && <LoadingScreen setLoading={setLoading} />}
      {!loading && (
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Home />
        </motion.div>
      )}
      {!loading && <Employee />}
      {!loading && <PlanCards />}
      {!loading && <OurServices />}
      {!loading && <Contact />}
      {!loading && (
        <button
          className="fixed bottom-2 right-4 bg-blue-500 hover:bg-blue-600 text-white py-4 px-8 rounded-full shadow-md"
          onClick={scrollToTop}
        >
          <FaArrowUp />
        </button>
      )}
      {!loading && (
        <motion.button
          className="fixed bottom-20 right-4 bg-green-500 hover:bg-green-600 text-white py-6 px-6 rounded-full shadow-md" // Aumente o padding para deixar o botão maior
          onClick={openWhatsApp}
          animate={{ y: [-5, 8, -5], transition: { duration: 1.5, repeat: Infinity } }} // Animação de pulo
        >
          <FaWhatsapp size={32} />
        </motion.button>
      )}
    </div>
  );
};

export default App;
