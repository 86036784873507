import React from 'react';
import colab from '../assets/colab.jpg';
import backgroundImage from '../assets/1.png';

const Employee = () => {
    return (
        <div
            style={{
                backgroundImage: `url(${backgroundImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
            }}
            className="bg-purple-900 flex flex-col justify-center items-center"
        >
            <div className="container mx-auto py-12 text-center">
                <h1 className="text-4xl font-bold mb-8 text-white">Colaboradores</h1>
                <div className="inline-flex grid-cols-1 gap-8">
                    <div className="bg-purple-800 rounded-lg p-6 flex flex-col items-center justify-center shadow-lg">
                        <img
                            src={colab}
                            alt="Nome do Colaborador"
                            className="rounded-full w-32 h-32 mb-4"
                        />
                        <b>
                            <h2 className="text-lg font-semibold mb-2 text-white">Erisson Almeida</h2>
                            <p className="text-sm text-gray-400">
                                Sou Fundador e Desenvolvedor de Software <br /> da <br />HorizonCode
                            </p>
                        </b>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Employee;
