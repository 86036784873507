import React from 'react';
import Lottie from 'react-lottie';
import animationData from '../assets/animations/construction-development.json';
import logo from '../assets/logo-sf.png';
import backgroundImage from '../assets/bg.png';
import { Link } from 'react-scroll';
import { Helmet } from 'react-helmet';

const Home = () => {
    return (
        <div>
            <Helmet>
                <meta name="description" content="Descubra como nossos serviços de desenvolvimento de software e tecnologia podem impulsionar o seu negócio. Oferecemos soluções personalizadas e inovadoras para atender às suas necessidades digitais. Nossa equipe experiente está pronta para desenvolver aplicativos web, móveis e de desktop, implementar sistemas de gerenciamento de banco de dados, fornecer consultoria em TI e muito mais. Entre em contato conosco para transformar suas ideias em realidade digital." />
                <meta name="keywords" content="desenvolvimento de software, desenvolvimento web, aplicativos móveis, tecnologia da informação, consultoria em TI, inteligência artificial, machine learning, blockchain, Internet das Coisas, segurança cibernética, nuvem, big data, análise de dados, programação, codificação, inovação tecnológica, criar site, criar aplicativo, criar programa, criar sistema, criar erp, criar crm" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <meta property="og:title" content="Horizon Code - Desenvolvimento de Software" />
                <meta property="og:description" content="Descubra como nossos serviços de desenvolvimento de software e tecnologia podem impulsionar o seu negócio. Oferecemos soluções personalizadas e inovadoras." />
                <meta property="og:image" content={logo} />
                <meta property="og:url" content="https://horizoncode.com.br" />
                <meta property="og:type" content="website" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Horizon Code - Desenvolvimento de Software" />
                <meta name="twitter:description" content="Descubra como nossos serviços de desenvolvimento de software e tecnologia podem impulsionar o seu negócio." />
                <meta name="twitter:image" content={logo} />

                <script type="application/ld+json">
                    {`
                    {
                      "@context": "https://schema.org",
                      "@type": "Organization",
                      "name": "Horizon Code",
                      "url": "https://horizoncode.com.br",
                      "logo": "${logo}",
                      "description": "Descubra como nossos serviços de desenvolvimento de software e tecnologia podem impulsionar o seu negócio."
                    }
                  `}
                </script>
            </Helmet>
            <div
                id='home'
                style={{
                    backgroundImage: `url(${backgroundImage})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                }}
                className="min-h-screen flex flex-col justify-center items-center text-white font-montserrat relative"
            >
                <nav className="absolute top-0 left-0 right-0 flex justify-between items-center p-4">
                    <img src={logo} alt="Logo" className="w-[4.5rem] h-[4.5rem] md:w-[6rem] md:h-[6rem] mr-4" />

                    <ul className="hidden md:flex">
                        <li className="mx-4 cursor-pointer">
                            <Link to="plan" smooth={true} duration={800}><b>Planos</b></Link>
                        </li>
                        <li className="mx-4 cursor-pointer">
                            <Link to="services" smooth={true} duration={800}><b>Serviços</b></Link>
                        </li>
                        <li className="mx-4 cursor-pointer">
                            <Link to="contact" smooth={true} duration={800}><b>Contatos</b></Link>
                        </li>
                    </ul>
                </nav>

                <div className="flex flex-col md:flex-row items-center justify-center md:justify-between mx-4">
                    <div class="text-center md:text-left mb-8 md:mb-0 md:mr-8 md:ml-40 max-w-md">
                        <h1 className="text-6xl font-bold mb-4">
                            <b style={{ color: '#a03ddd' }}>Horizon </b>
                            <b style={{ color: '#48c0ff' }}>Code</b>
                        </h1>
                        <p className="text-lg leading-normal">
                            Bem-vindo à Horizon Code,
                            uma empresa de desenvolvimento de software dedicada a oferecer soluções inovadoras e personalizadas para atender às necessidades específicas dos nossos clientes.
                            Comprometido em fornecer produtos de alta qualidade, desde aplicativos móveis e web até sistemas empresariais escaláveis.
                        </p>
                    </div>

                    <div className="w-full md:w-1/2 flex justify-center">
                        <Lottie
                            isClickToPauseDisabled={true}
                            options={{
                                loop: true,
                                autoplay: true,
                                animationData
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home;
